import { useParams } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import STRING_KEYS from '../../../../language/keys';
import AtsInitializationDialogs
  from '../../../../components/dialogs/ats-initialization-dialogs/ats-initialization-dialogs.component';
import { PRODUCT_AREA } from '../../../../consts/analytics';
import { useShouldLockFeature } from '../../../../hooks/should-lock-feature.hook';
import StatisticCard from '../../../../components/Common/statistic-card/statistic-card.component';
import { THEME } from '../../../../themes';
import PositionPerformanceFunnel
  from '../../components/position-performance-funnel/position-performance-funnel.component';
import { useGetPositionStatisticsQuerySelector } from '../../../../store/rtk-query/apis/position/selectors/get-position.selector';
import { positionQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { ConversationStatusEnum } from '../../../../store/rtk-query/apis/outreach/enums/conversation-status.enum';
import InviteColleagueModal
  from '../../../../components/dialogs/invite-colleague-modal/invite-colleague-modal.component';
import PositionCardSettings from '../../components/position-card-settings/position-card-settings.component';
import ClosePositionModal from '../../../../components/dialogs/close-position-modal/close-position-modal.component';
import { IconButtonVariants } from '../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import UserPickersContainer from '../../components/user-pickers-container/user-pickers-container.component';
import PositionLocation from '../../../../components/Common/position-location/position-location.component';
import { InfoSnackBar } from '../../../../components/Common/CustomSnackBar/InfoSnackBar';
import { useExportEngagedMatchesCSV } from '../../hooks/export-csv.hook';
import { UpgradePlanDialog } from '../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import PositionAssistant from '../../components/position-assistant/position-assistants.component';
import {
  useSkipPositionAssistantMutation,
} from '../../../../store/rtk-query/apis/position/hooks/skip-position-assistant.mutation-hook';
import TalentRarity from '../../components/position-realization-metrics/talent-rarity/talent-rarity.component';
import EffortInvested from '../../components/position-realization-metrics/effort-invested/effort-invested.component';
import PageLoader from '../../components/page-loader/page-loader.component';
import {
  AccentText,
  AtsConnected,
  AtsIcon, CheckedIcon,
  ConnectPositionToAts,
  Container,
  ExportMatchesIcon, Label,
  PageBody,
  PageHeader,
  PageHeaderRightContainer,
  PositionInfoBottom,
  PositionPerformanceFunnelContainer,
  PositionTitle, RealizationMetricsContainer, ScrollContainer,
  SettingsButton,
  SettingsButtonIcon, StatisticsCardsContainer, StatisticsCardsLabelContainer,
  StatisticsCardsMainContainer, StatisticSideContainer,
  ToReachoutIcon,
  ToReplyCardIcon,
  ToReviewCardDailyLimitBodyIcon,
  ToReviewCardIcon,
  ToReviewCardMatchingProgressBodyIcon,
  VerticalDivider,
} from './position-overview.styled';

const PositionOverviewPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const { data: position, isLoading: isPositionLoading } = useGetPositionByIdQuery(positionId);
  const isLocked = useShouldLockFeature();
  const [isAtsInitializationDialogOpen, setIsAtsInitializationDialogOpen] = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const [isClosePositionModalOpen, setIsClosePositionModalOpen] = useState<boolean>(false);
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);
  const [isInfoSnackBarOpen, setIsInfoSnackBarOpen] = useState(false);
  const [infoSnackBarText, setInfoSnackBarText] = useState('');

  const [
    getPositionOverview,
    { data: positionOverview, isLoading: isPositionOverviewLoading, isFetching: isPositionOverviewFetching },
  ] = positionQuery.useLazyGetPositionOverviewQuery();
  const [skipPositionAssistant] = useSkipPositionAssistantMutation();
  const exportCSV = useExportEngagedMatchesCSV(positionId, position?.jobTitle);

  const handleExportCSVClick = async () => {
    await exportCSV();
    setInfoSnackBarText(translate(STRING_KEYS.EXPORT_MATCHES.SUCCESS_MESSAGE));
    setIsInfoSnackBarOpen(true);
  };

  const {
    data: {
      isDailyLimit, matchingProgress, toReviewMatches, noResults,
    },
  } = useGetPositionStatisticsQuerySelector(positionId);
  const history = useHistory();

  useEffect(() => {
    if (!matchingProgress) {
      getPositionOverview(positionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingProgress]);

  useEffect(() => {
    getPositionOverview(positionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  const onConnectPositionToAtsButtonClick = () => {
    if (isLocked) {
      setIsUpgradeDialogOpen(true);
    } else {
      setIsAtsInitializationDialogOpen(true);
    }
  };

  const renderAtsInfo = () => {
    const isPositionConnectedToAts = !!position.linkedAtsJob;

    if (isPositionConnectedToAts) {
      return (
        <AtsConnected>
          <AtsIcon />
          <Trans
            i18nKey={ STRING_KEYS.POSITION_OVERVIEW_PAGE.ATS.SYNCED_TEXT }
            values={ { positionName: position.linkedAtsJob?.jobTitle } }
          >
            <AccentText />
          </Trans>
        </AtsConnected>
      );
    }

    return (
      <ConnectPositionToAts onClick={ onConnectPositionToAtsButtonClick }>
        <AtsIcon />
        { translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.ATS.CONNECT_TO_ATS_TEXT) }
      </ConnectPositionToAts>
    );
  };

  const renderToReviewStatisticCard = (): JSX.Element | undefined => {
    if (!positionOverview?.positionStatistics) {
      return undefined;
    }

    let infoTextTranslationKey = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT;
    let bodyIcon;
    const count = matchingProgress ? 0 : toReviewMatches;
    if (isDailyLimit) {
      infoTextTranslationKey = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT_DAILY_LIMIT;
      bodyIcon = ToReviewCardDailyLimitBodyIcon;
    } else if (matchingProgress) {
      infoTextTranslationKey = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT_MATCHING_PROGRESS;
      bodyIcon = ToReviewCardMatchingProgressBodyIcon;
    } else if (noResults) {
      infoTextTranslationKey = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT_NO_MATCHES_FOUND;
      bodyIcon = ToReviewCardDailyLimitBodyIcon;
    }

    return (
      <StatisticCard
        title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE) }
        TitleIcon={ ToReviewCardIcon }
        BodyIcon={ bodyIcon }
        count={ count }
        activeColor={ THEME.COLORS.ACCENT }
        infoText={ infoTextTranslationKey ? translate(infoTextTranslationKey) : undefined }
        actionButtonText={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.ACTION_BUTTON_TEXT) }
        clickHandler={ () => history.push(AppRouting.buildMatchesPageUrl(positionId)) }
      />
    );
  };

  const renderToReachoutStatisticCard = (): JSX.Element | undefined => {
    if (!positionOverview?.positionStatistics) {
      return undefined;
    }

    return (
      <StatisticCard
        title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.TITLE) }
        TitleIcon={ ToReachoutIcon }
        count={ positionOverview?.positionStatistics?.newConversations }
        activeColor={ THEME.COLORS.SECONDARY }
        infoText={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.INFO_TEXT) }
        actionButtonText={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.ACTION_BUTTON_TEXT) }
        clickHandler={ () => history.push(AppRouting.buildPositionConversationsPageUrl(positionId, ConversationStatusEnum.New)) }
      />
    );
  };

  const renderToReplyStatisticCard = (): JSX.Element | undefined => {
    if (!positionOverview?.positionStatistics) {
      return undefined;
    }

    return (
      <StatisticCard
        title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REPLY.TITLE) }
        TitleIcon={ ToReplyCardIcon }
        count={ positionOverview?.positionStatistics.newRepliedConversations }
        activeColor={ THEME.COLORS.DECOR }
        infoText={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REPLY.INFO_TEXT) }
        actionButtonText={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REPLY.ACTION_BUTTON_TEXT) }
        clickHandler={ () => history.push(AppRouting.buildPositionConversationsPageUrl(positionId, ConversationStatusEnum.Replied)) }
      />
    );
  };

  const renderRealizationMetrics = (): JSX.Element | undefined => {
    if (!positionOverview?.positionStatistics) {
      return undefined;
    }

    return (
      <RealizationMetricsContainer>
        <TalentRarity />
        <EffortInvested
          timeSavedInSeconds={ positionOverview?.positionMetrics?.timeSaved }
          totalPageVisitsDurationInSeconds={ positionOverview?.positionMetrics?.totalPageVisitsDuration }
          positionCreatedAt={ new Date(position?.createdAt) }
        />
      </RealizationMetricsContainer>
    );
  };
  const renderStatisticsCards = (): JSX.Element | undefined => {
    if (!positionOverview?.positionStatistics) {
      return undefined;
    }

    return (
      <StatisticsCardsMainContainer>
        <StatisticsCardsLabelContainer>
          <CheckedIcon />
          <Label>{ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.LABEL) }</Label>
        </StatisticsCardsLabelContainer>
        <StatisticsCardsContainer>
          { renderToReviewStatisticCard() }
          { renderToReachoutStatisticCard() }
          { renderToReplyStatisticCard() }
        </StatisticsCardsContainer>
      </StatisticsCardsMainContainer>
    );
  };

  const onClosePositionModalClose = (args?: OnClosePositionModalArguments) => {
    setIsClosePositionModalOpen(false);

    if (args?.isPositionClosedSuccessfully) {
      history.push(AppRouting.buildHomePagePath());
    }
  };

  if (!positionOverview?.positionStatistics || isPositionLoading || isPositionOverviewLoading || isPositionOverviewFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <ScrollContainer>
        <Container>
          <PageHeader>
            <div>
              <PositionTitle>{ position.jobTitle }</PositionTitle>
              <PositionInfoBottom>
                <PositionLocation remotePosition={ !!position.remotePosition } locations={ position.locations } />
                { renderAtsInfo() }
              </PositionInfoBottom>
            </div>
            <PageHeaderRightContainer>
              <UserPickersContainer
                position={ position }
                openInviteUsersModalHandler={ () => setIsInviteUsersModalOpen(true) }
                showAddUserIcon
              />
              <VerticalDivider />
              <PositionCardSettings
                ActionButton={ (
                  <SettingsButton
                    type="button"
                    variant={ IconButtonVariants.Ghost }
                    hoverVariant={ PerfectButtonHoverVariants.Grow }
                    StartIcon={ SettingsButtonIcon }
                  />
                ) }
                atsInitializationHandler={ () => setIsAtsInitializationDialogOpen(true) }
                closePositionHandler={ () => setIsClosePositionModalOpen(true) }
                isAtsIntegrated={ !!position.linkedAtsJob }
                exportMatchesHandler={ handleExportCSVClick }
              />
            </PageHeaderRightContainer>
          </PageHeader>
          <PageBody>
            <StatisticSideContainer>
              { renderStatisticsCards() }
              { renderRealizationMetrics() }
            </StatisticSideContainer>
            <PositionAssistant
              tasks={ positionOverview?.assistant?.tasks || [] }
              milestones={ positionOverview?.assistant?.milestones || [] }
              positionStatistics={ positionOverview?.positionStatistics }
              connectToATSHandler={ onConnectPositionToAtsButtonClick }
              skipPositionAssistantHandler={ skipPositionAssistant }
            />
          </PageBody>
          <PositionPerformanceFunnelContainer>
            <PositionPerformanceFunnel positionStatistics={ positionOverview?.positionStatistics } />
          </PositionPerformanceFunnelContainer>
        </Container>
      </ScrollContainer>
      { isAtsInitializationDialogOpen && (
        <AtsInitializationDialogs
          positionId={ positionId }
          isOpen={ isAtsInitializationDialogOpen }
          closeHandler={ () => setIsAtsInitializationDialogOpen(false) }
          productArea={ PRODUCT_AREA.POSITION_OVERVIEW_PAGE }
        />
      )}
      { isUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isUpgradeDialogOpen }
          onCloseDialog={ () => setIsUpgradeDialogOpen(false) }
        />
      )}
      { isInviteUsersModalOpen && (
        <InviteColleagueModal
          isOpen={ isInviteUsersModalOpen }
          closeHandler={ () => setIsInviteUsersModalOpen(false) }
        />
      ) }
      {
        isClosePositionModalOpen && (
          <ClosePositionModal
            positionId={ positionId }
            onClose={ onClosePositionModalClose }
          />
        )
      }
      {
        isClosePositionModalOpen && (
          <ClosePositionModal
            positionId={ positionId }
            onClose={ onClosePositionModalClose }
          />
        )
      }
      <InfoSnackBar
        title={ infoSnackBarText }
        isOpen={ isInfoSnackBarOpen }
        onClose={ () => setIsInfoSnackBarOpen(false) }
        shouldAutoDismiss
        icon={ ExportMatchesIcon }
      />
    </>
  );
};

export default PositionOverviewPage;
