import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import STRING_KEYS from '../../../../language/keys';
import { MatchingProgressLoader } from '../../../../pages/matching-progress-page/matching-progress-loader/matching-progress-loader.component';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { PositionRunTriggerTypeEnum } from '../../../../enums/position-run-trigger-type.enum';
import {
  Container, DescriptionIcon, HeaderContainer, HeaderDescription, HeaderTextContainer, HeaderTitle,
} from './matching-progress.styled';

const MatchingProgress = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const { data: position } = useGetPositionByIdQuery(positionId);

  const renderText = () => {
    const isAdjustmentRun = position.latestRun?.triggerType === PositionRunTriggerTypeEnum.DeadEndRecommendation;

    const titleKey = isAdjustmentRun ?
      STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE_ADJUSTMENT :
      STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE;

    const descriptionKey = isAdjustmentRun ?
      STRING_KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION_ADJUSTMENT :
      STRING_KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION;

    return (
      <HeaderTextContainer>
        <HeaderTitle>
          { translate(titleKey) }
        </HeaderTitle>
        <HeaderDescription>
          { !isAdjustmentRun && <DescriptionIcon /> }
          { translate(descriptionKey) }
        </HeaderDescription>
      </HeaderTextContainer>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        { renderText() }
        <MatchingProgressLoader
          positionId={ position.id }
          positionTriggeredAt={ position?.latestRun?.triggeredAt }
        />
      </HeaderContainer>
    </Container>
  );
};

export default MatchingProgress;
